import * as React from "react"
import {useEffect, useRef, useState} from "react"

import '../styles/global.scss'
import IconoTiempo from '../images/icon-tiempo.png'
import IconoTempMax from '../images/icon-max.png'
import IconoTempMin from '../images/icon-min.png'
import VideoIntro from '../videos/tiempo-intro.mp4'
import VideoOutro from '../videos/tiempo-outro.mp4'


const IndexPage = ({ data }) => {
  const [introVideo, setIntroVideo] = useState(true);
  const [displayedEvent, setDisplayedEvent] = useState(null);
  const [outroVideo, setOutroVideo] = useState(false);

  const displayedEventRef = useRef(displayedEvent);
  const videoOutroRef = useRef(null);

  displayedEventRef.current = displayedEvent;

  const currentWeather = {
    icon: '10',
    currentTemp: 17,
    maxTemp: 19,
    minTemp: 12
  }

  const assets = data.tiempoAssets.edges.filter((edge) => edge.node.uid === currentWeather.icon)[0].node;

  const introEnd = () => {
    setIntroVideo(false)
    setDisplayedEvent(1)
  }


  useEffect(() => {
    let interval;
    if (!introVideo && !outroVideo) {
      interval = setInterval(() => {
        let currDisplayedEvent = displayedEventRef.current;
        //console.log('transition', currDisplayedEvent, noticias.length - 1, currDisplayedEvent + 1 < noticias.length - 1 )
        if (currDisplayedEvent + 1 < 3) {
          setDisplayedEvent(currDisplayedEvent => currDisplayedEvent + 1)
        } else {
          setOutroVideo(true)
          setDisplayedEvent(null)
          videoOutroRef.current.play();
        }
      }, 6000)
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [introVideo, outroVideo]);

  const mainBackgroundStyle = {
    backgroundImage: `url("${assets.imagen.localFile.publicURL}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    //backgroundBlendMode: 'multiply',
    //backgroundColor: '#DEDEDE'
  }

  return (
    <main className="main tiempo">
      <video style={{opacity: introVideo ? 1 : 0}} src={VideoIntro} autoPlay={true} controls={false} muted={true} onEnded={() => {introEnd()}}/>

      <section className={`header-container flex-set flex--row flex--align-items-center flex--content-center ${introVideo ? '' : 'slideInDown'}`}>
        <img src={IconoTiempo} className="header-icon" alt=""/>
        <span className="header-text text-uppercase">Eguraldia</span>
      </section>

      <section className={`content-container ${(!introVideo && !outroVideo) ? 'zoomIn long' : ''}`} style={mainBackgroundStyle}/>

      <section style={{display: (!introVideo && !outroVideo && displayedEventRef.current === 1) ? 'block' : 'none'}}>
        <div className={`weather-current-temp-container flex-set flex--row flex--align-items-center flex--content-center ${(!introVideo && !outroVideo && displayedEventRef.current === 1) ? 'fadeIn' : ''}`}>
          <img className="weather-current-icon" src={assets.icono.localFile.publicURL} alt=""/>
          <span className="font-weather-current">{currentWeather.currentTemp}º</span>
        </div>

        <div className={`weather-maxmin-temp-container flex-set flex--row flex--align-items-center flex--content-center ${(!introVideo && !outroVideo && displayedEventRef.current === 1) ? 'fadeIn' : ''}`}>
          <div className={"weather-maxmin-temp-subcontainer"}>
            <img className="weather-maxmin-icon" src={IconoTempMax} alt=""/>
            <span className="font-weather-maxmin">{currentWeather.maxTemp}º</span>
          </div>

          <div className={"weather-maxmin-temp-subcontainer"}>
            <img className="weather-maxmin-icon" src={IconoTempMin} alt=""/>
            <span className="font-weather-maxmin">{currentWeather.minTemp}º</span>
          </div>

        </div>
      </section>

      <section style={{display: (!introVideo && !outroVideo && displayedEventRef.current === 2) ? 'block' : 'none'}}>
        <div className={`weather-forecast-container flex-set flex--row flex--align-items-center flex--content-around ${(!introVideo && !outroVideo && displayedEventRef.current === 2) ? 'fadeIn' : ''}`}>
          <div className={`flex-set flex--column flex--align-items-center flex--content-center weather-forecast-content`}>
            <div style={{minHeight: '65px'}}>
              <img src={data.tiempoAssets.edges[1].node.icono.localFile.publicURL} className="weather-forecast-icon" alt=""/>
            </div>
            <span className={"font-weather-dayname"}>Az</span>
            <span className={"font-weather-maxmin"}>17º</span>
            <span className={"font-weather-maxmin font-weather-black"}>11º</span>
          </div>
          <div className={`flex-set flex--column flex--align-items-center flex--content-center weather-forecast-content`}>
            <div style={{minHeight: '65x'}}>
              <img src={data.tiempoAssets.edges[4].node.icono.localFile.publicURL} className="weather-forecast-icon" alt=""/>
            </div>
            <span className={"font-weather-dayname"}>Og</span>
            <span className={"font-weather-maxmin"}>16º</span>
            <span className={"font-weather-maxmin font-weather-black"}>11º</span>
          </div>
          <div className={`flex-set flex--column flex--align-items-center flex--content-center weather-forecast-content`}>
            <div style={{minHeight: '65px'}}>
              <img src={data.tiempoAssets.edges[4].node.icono.localFile.publicURL} className="weather-forecast-icon" alt=""/>
            </div>
            <span className={"font-weather-dayname"}>Or</span>
            <span className={"font-weather-maxmin"}>17º</span>
            <span className={"font-weather-maxmin font-weather-black"}>9º</span>
          </div>
          <div className={`flex-set flex--column flex--align-items-center flex--content-center weather-forecast-content`}>
            <div style={{minHeight: '65spx'}}>
              <img src={data.tiempoAssets.edges[5].node.icono.localFile.publicURL} className="weather-forecast-icon" alt=""/>
            </div>
            <span className={"font-weather-dayname"}>Lr</span>
            <span className={"font-weather-maxmin"}>11º</span>
            <span className={"font-weather-maxmin font-weather-black"}>5º</span>
          </div>
        </div>
      </section>

      <video ref={videoOutroRef} style={{opacity: outroVideo ? 1 : 0}} src={VideoOutro} autoPlay={false} controls={false} muted={true}/>

    </main>
  )
}

export const pageQuery = graphql`  
  {
    tiempoAssets: allStrapiTiempoAssets {
      edges {
        node {
          icono {
            localFile {
              publicURL
            }
          }
          imagen {
            localFile {
              publicURL
            }
          }
          uid
        }
      }
    }
  }
`

export default IndexPage